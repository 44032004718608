<template>
  <div class="music-artist-app">
    <nav class="nav" role="navigation" aria-label="main navigation">
      <router-link to="/">Home</router-link>
    </nav>
    <div class="app-description">
      <h1>Musical Artist Application</h1>
      <p class="mt-6">
        Please fill out this application if you are a musical artist or artist
        representative interested in performing at the Bacchanalia 2022
        Festival. The deadline for submissions is July 31st, 2022. All
        submissions will be reviewed and chosen performers will be notified via
        email and/or text message. All performers will receive a small travel
        stipend (not to exceed $200), as well as festival passes for all members
        of the group + two additional crew members.
      </p>
    </div>
    <div v-if="!submitSuccess" class="music-artist-app__form">
      <div class="field">
        <label class="label">Performer / Group Name</label>
        <div class="control">
          <input v-model="performerName" class="input" type="text" />
        </div>
      </div>

      <div class="field">
        <label class="label">Number of Members</label>
        <div class="control">
          <input v-model="numMembers" class="input" type="text" />
        </div>
      </div>

      <div class="field">
        <label class="label">Contact Person</label>
        <div class="control">
          <input v-model="contactPerson" class="input" type="text" />
        </div>
      </div>

      <div class="field">
        <label class="label">Contact Phone</label>
        <div class="control">
          <input v-model="contactPhone" class="input" type="phone" />
        </div>
      </div>

      <div class="field">
        <label class="label">Contact Email</label>
        <div class="control">
          <input v-model="contactEmail" class="input" type="email" />
        </div>
      </div>

      <div class="field">
        <label class="label">Contact Address</label>
        <div class="control">
          <input v-model="address" class="input" type="text" />
        </div>
      </div>

      <div class="field">
        <label class="label"
          >Primary website or link to artist page / EPK</label
        >
        <div class="control">
          <input v-model="primaryWebsite" class="input" type="text" />
        </div>
        <p class="help">A sample of music must be available on this page.</p>
      </div>

      <div class="field">
        <label class="label"
          >Please add any additional links to relevant materials</label
        >
        <div class="control">
          <textarea v-model="additionalLinks" class="textarea" />
        </div>
        <p class="help">e.g. Soundcloud, Bandcamp, Spotify</p>
      </div>

      <div class="field">
        <label class="label">Musical Genre (primary)</label>
        <div class="control radio-list">
          <label
            class="radio"
            v-for="genreOption in genreOptions"
            :key="genreOption"
          >
            <input
              type="radio"
              name="genre"
              v-model="genre"
              :value="genreOption"
            />
            {{ genreOption }}
          </label>
        </div>
      </div>

      <div class="field">
        <label class="label">Available Dates (check all that apply)</label>
        <div class="control radio-list">
          <label
            class="radio"
            v-for="dateOption in dateOptions"
            :key="dateOption"
          >
            <input
              type="checkbox"
              name="dateOption"
              v-model="availableDates"
              :value="dateOption"
            />
            {{ dateOption }}
          </label>
        </div>
      </div>

      <div class="field">
        <label class="label">What equipment are you bringing?</label>
        <div class="control">
          <textarea v-model="equipment" class="textarea" />
        </div>
        <p class="help">
          While some backline might be available, it is not guaranteed.
        </p>
      </div>

      <div class="field">
        <label class="label">Number of vocal microphones needed</label>
        <div class="control">
          <input v-model="numMics" class="input" type="text" />
        </div>
      </div>

      <div class="field">
        <label class="label">Number of direct inputs needed</label>
        <div class="control">
          <input v-model="numDIs" class="input" type="text" />
        </div>
      </div>

      <div class="field">
        <label class="label"
          >Do you have any other special requests or information related to your
          stage setup or for the sound technician?</label
        >
        <div class="control">
          <textarea v-model="soundEngineerRequests" class="textarea" />
        </div>
      </div>

      <div class="field">
        <label class="label"
          >Please provide a brief introduction to your group that may be used
          for marketing purposes</label
        >
        <div class="control">
          <textarea v-model="introduction" class="textarea" />
        </div>
      </div>

      <div class="field is-grouped">
        <div class="control">
          <button class="button is-link" @click="submit">SUBMIT</button>
        </div>
      </div>
    </div>
    <div v-if="submitSuccess" class="submit-success mt-6">
      Thank you for submitting your application! You will be contacted as soon
      as possible if your group has been selected to be a part of the festival.
      We are looking forward to reviewing the submission!<br />- The BAMF Crew
    </div>
  </div>
</template>

<script>
// import axios from "axios";

export default {
  data() {
    return {
      // Form fields
      performerName: "",
      numMembers: "",
      contactPerson: "",
      contactPhone: "",
      contactEmail: "",
      address: "",
      primaryWebsite: "",
      additionalLinks: "",
      genre: "",
      availableDates: [],
      equipment: "",
      numMics: "",
      numDIs: "",
      soundEngineerRequests: "",
      introduction: "",
      // Options
      genreOptions: [
        "Pop",
        "Rock",
        "Hip Hop",
        "Electronic",
        "Country",
        "Folk / Acoustic",
        "Jazz",
        "Metal",
        "Hispanic",
      ],
      dateOptions: [
        "Friday, September 30th - Night",
        "Saturday, October 1st - Day",
        "Saturday, October 1st - Night",
        "Sunday, October 2nd - Day",
        "Sunday, October 2nd - Night",
      ],
      // Form
      isSubmitting: false,
      submitSuccess: false,
    };
  },
  methods: {
    async submit() {
      this.isSubmitting = true;
      const fd = new URLSearchParams();
      fd.append("performerName", this.performerName);
      fd.append("numMembers", this.numMembers);
      fd.append("contactPerson", this.contactPerson);
      fd.append("contactPhone", this.contactPhone);
      fd.append("contactEmail", this.contactEmail);
      fd.append("address", this.address);
      fd.append("primaryWebsite", this.primaryWebsite);
      fd.append("additionalLinks", this.additionalLinks);
      fd.append("genre", this.genre);
      fd.append("availableDates", this.availableDates.join(", "));
      fd.append("equipment", this.equipment);
      fd.append("numMics", this.numMics);
      fd.append("numDIs", this.numDIs);
      fd.append("soundEngineerRequests", this.soundEngineerRequests);
      fd.append("introduction", this.introduction);
      try {
        await fetch(
          "https://script.google.com/macros/s/AKfycbzviRBwqVhHC6lY8USSg_n9ZYf9AH8lUhuEIIaVQIdB_x_70WZ7tHCWjP9bpG_Q-n33/exec",
          {
            method: "POST",
            mode: "no-cors",
            body: fd,
          }
        );
        window.fbq("track", "SubmitApplication");
        this.submitSuccess = true;
      } catch (e) {
        alert("There was an error submitting. Please try again!");
      }
      this.isSubmitting = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.music-artist-app {
  background: url("../assets/img/HeroBackground1920.jpeg");
  background-position: bottom right;
  background-size: cover;
  color: #fff;
  font-family: "ArchivoBlack", Arial, Helvetica, sans-serif;
  min-height: 100vh;

  h1 {
    font-size: 3em;
    line-height: 1.1em;
  }
  .app-description {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 1rem;
  }
  button {
    font-family: "ArchivoBlack", Arial, Helvetica, sans-serif;
    background-color: var(--color-yellow);
  }
  .field {
    padding-top: 1rem;
  }
  label {
    color: #fff;
  }

  &__form {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 1rem;
    text-align: left;
  }
  .radio-list {
    .radio {
      display: block;

      & + .radio {
        margin-left: 0;
        margin-top: 0.5em;
      }
    }
  }
  .submit-success {
    text-align: center;
    font-size: 1.5em;
    padding: 1rem;
  }
}
.nav {
  padding: 1rem;
  text-align: right;
}
</style>
